import React from "react"
import Layout from "../components/layout.js"

const ExamplesPage = () => {
  return (
    <Layout>
    <div className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <h2 className="title is-2">Example Level 2 heading</h2>
            <p className="content">
    Example 1
            </p>
          </div>
          <div className="column">
            <h2 className="title is-2">Example Level 2 heading</h2>
            <p className="content">
    Example 2
            </p>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  )
}

export default ExamplesPage
